import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "stålhästen" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "stålhästen-elcykel---din-guide-till-elektriska-cyklar"
    }}>{`Stålhästen Elcykel - Din Guide till Elektriska Cyklar`}</h1>
    <p>{`Välkommen till vår e-handel för stålhästen elcyklar, där vi erbjuder högkvalitativa och stilfulla elektriska cyklar för alla typer av pendlare och cykelälskare. Stålhästen, ett välkänt och respekterat namn inom cykelindustrin, har designat flera serier av elektriska cyklar som kombinerar modern teknik, elegant design och hållbar funktionalitet. Här presenterar vi de olika serierna av stålhästen elcyklar och deras unika egenskaper.`}</p>
    <h2 {...{
      "id": "stålhästen-e-prima-serie"
    }}>{`Stålhästen E-Prima Serie`}</h2>
    <h3 {...{
      "id": "1-e-prima-grön"
    }}>{`1. E-Prima Grön`}</h3>
    <p><strong parentName="p">{`E-Prima Grön`}</strong>{` är den perfekta blandningen av stil och funktionalitet. Designad för att erbjuda både komfort och prestanda, denna elcykel kommer med:`}</p>
    <ul>
      <li parentName="ul">{`Effektiv 250 watts framhjulsmotor`}</li>
      <li parentName="ul">{`Hållbart 14,5 Ah 36 volts batteri`}</li>
      <li parentName="ul">{`Toppfart på 25 km/h och en räckvidd på upp till 70 km`}</li>
      <li parentName="ul">{`7 Shimano Nexus växlar`}</li>
      <li parentName="ul">{`Punkteringsskyddade däck för smidig körning`}</li>
      <li parentName="ul">{`Vattentät display som visar alla kördetaljer`}</li>
    </ul>
    <p>{`E-Prima Grön är idealisk för den moderna cyklisten som vill ha en kombination av design och avancerad teknik.`}</p>
    <h3 {...{
      "id": "2-e-prima-svart"
    }}>{`2. E-Prima Svart`}</h3>
    <p><strong parentName="p">{`E-Prima Svart`}</strong>{` är en toppmodern elcykel som erbjuder en unik balans mellan stil, komfort och miljövänlig teknik. Denna cykel har alla funktioner du behöver för en fantastisk cykeltur:`}</p>
    <ul>
      <li parentName="ul">{`Aluminiumram och kraftfull framhjulsmotor`}</li>
      <li parentName="ul">{`7-växlat Shimano Nexus nav`}</li>
      <li parentName="ul">{`Hydraulisk skivbroms för säker körning`}</li>
      <li parentName="ul">{`Avtagbart batteri för enkel laddning med en räckvidd på upp till 70 km`}</li>
      <li parentName="ul">{`Punkteringsskyddade däck och dynamisk LED-belysning`}</li>
      <li parentName="ul">{`Flera hjälpnivåer och en intuitiv display`}</li>
    </ul>
    <p>{`Med E-Prima Svart får du en förstklassig cykelupplevelse som är perfekt för både stadspendling och längre utflykter.`}</p>
    <h2 {...{
      "id": "köpguiden-för-stålhästen-elcykel"
    }}>{`Köpguiden för Stålhästen Elcykel`}</h2>
    <p>{`Att välja rätt elcykel kan vara en utmaning, särskilt när du har flera fantastiska alternativ från stålhästen. Här är några tips för att hjälpa dig att hitta den perfekta elcykeln:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Användning`}</strong>{`: Tänk på hur du planerar att använda din elcykel. För dagliga pendlingsresor är både E-Prima Grön och E-Prima Svart utmärkta val, tack vare deras hållbara batterier och smidiga växlingssystem.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Batterikapacitet`}</strong>{`: Om du planerar längre resor kan räckvidden på upp till 70 km vara en avgörande faktor. Både E-Prima Grön och E-Prima Svart erbjuder denna räckvidd.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Design`}</strong>{`: Välj den design som tilltalar dig mest. E-Prima Grön erbjuder en fräsch och modern look, medan E-Prima Svart har ett stilrent och elegant utseende.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Komfort och Säkerhet`}</strong>{`: Se till att du känner dig bekväm och trygg på din cykel. Båda modellerna har avancerade säkerhetsfunktioner som punkteringsskyddade däck och hydrauliska bromsar.`}</p>
      </li>
    </ol>
    <p>{`Vi hoppas att denna guide hjälper dig att göra ett informerat val och att du hittar den perfekta stålhästen elcykeln som kommer att förvandla dina dagliga resor till en njutbar upplevelse. Upptäck vårt sortiment och hitta din nya favoritcykel idag!`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      